/* Estilos personalizados para o conteúdo do blog */

/* Estilização da barra de rolagem */
.blog-content {
  scrollbar-width: thin;
  scrollbar-color: #3B82F6 #F3F4F6;
}

.blog-content::-webkit-scrollbar {
  width: 8px;
}

.blog-content::-webkit-scrollbar-track {
  background: #F3F4F6;
  border-radius: 4px;
}

.blog-content::-webkit-scrollbar-thumb {
  background-color: #3B82F6;
  border-radius: 4px;
  border: 2px solid #F3F4F6;
}

.blog-content::-webkit-scrollbar-thumb:hover {
  background-color: #2563EB;
}

/* Estilos para o conteúdo */
.blog-content {
  color: #1F2937;
  line-height: 1.8;
  font-size: 1.125rem;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 16px;
}

/* Estilos para títulos */
.blog-content h1,
.blog-content-h1 {
  font-size: 2.5rem;
  font-weight: 800;
  color: #111827;
  margin: 2.5rem 0 1.5rem;
  line-height: 1.2;
}

.blog-content h2,
.blog-content-h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #1F2937;
  margin: 2.25rem 0 1.25rem;
  line-height: 1.3;
  border-bottom: 2px solid #E5E7EB;
  padding-bottom: 0.5rem;
}

.blog-content h3,
.blog-content-h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #374151;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.blog-content h4,
.blog-content-h4 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4B5563;
  margin: 1.75rem 0 0.75rem;
  line-height: 1.4;
}

/* Estilos para parágrafos */
.blog-content p,
.blog-content-p {
  margin: 1.25rem 0;
  line-height: 1.8;
}

/* Estilos para links */
.blog-content a,
.blog-content-link {
  color: #3B82F6;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
}

.blog-content a:hover,
.blog-content-link:hover {
  color: #2563EB;
  border-bottom-color: #2563EB;
}

/* Estilos para listas */
.blog-content ul,
.blog-content-ul,
.blog-content ol,
.blog-content-ol {
  margin: 1.25rem 0;
  padding-left: 1.5rem;
}

.blog-content ul,
.blog-content-ul {
  list-style-type: disc;
}

.blog-content ol,
.blog-content-ol {
  list-style-type: decimal;
}

.blog-content li,
.blog-content-li {
  margin: 0.5rem 0;
}

/* Estilos para citações */
.blog-content blockquote,
.blog-content-blockquote {
  margin: 1.5rem 0;
  padding: 1rem 1.5rem;
  border-left: 4px solid #3B82F6;
  background-color: #F3F4F6;
  font-style: italic;
  color: #4B5563;
}

/* Estilos para código */
.blog-content code,
.blog-content-code {
  background-color: #F3F4F6;
  padding: 0.2rem 0.4rem;
  border-radius: 0.25rem;
  font-family: ui-monospace, monospace;
  font-size: 0.875em;
  color: #DC2626;
}

.blog-content pre,
.blog-content-pre {
  background-color: #1F2937;
  color: #F9FAFB;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-x: auto;
  margin: 1.5rem 0;
}

.blog-content pre code,
.blog-content-pre .blog-content-code {
  background-color: transparent;
  color: inherit;
  padding: 0;
  border-radius: 0;
}

/* Estilos para imagens */
.blog-content img,
.blog-content-img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin: 1.5rem 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Estilos para elementos de ênfase */
.blog-content strong,
.blog-content-strong {
  font-weight: 600;
  color: #111827;
}

.blog-content em,
.blog-content-em {
  font-style: italic;
  color: #4B5563;
}

/* Estilos para tabelas */
.blog-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
  border: 1px solid #E5E7EB;
}

.blog-content th,
.blog-content td {
  padding: 0.75rem 1rem;
  border: 1px solid #E5E7EB;
}

.blog-content th {
  background-color: #F9FAFB;
  font-weight: 600;
  text-align: left;
}

.blog-content tr:nth-child(even) {
  background-color: #F9FAFB;
}

/* Estilos para elementos horizontais */
.blog-content hr {
  margin: 2rem 0;
  border: 0;
  border-top: 2px solid #E5E7EB;
} 