@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background text-text;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply text-text-dark font-bold;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary hover:bg-primary-dark text-background-light font-bold py-2 px-4 rounded transition-colors shadow-md;
  }
  
  .btn-accent {
    @apply bg-accent hover:bg-accent-light text-primary-dark font-bold py-2 px-4 rounded transition-colors shadow-md;
  }

  .btn-outline-accent {
    @apply bg-transparent hover:bg-accent text-accent hover:text-primary-dark font-bold py-2 px-4 rounded transition-colors border border-accent;
  }

  .card {
    @apply bg-background-light rounded-lg shadow-md p-6 border border-background;
  }

  .section {
    @apply py-12;
  }

  .container {
    @apply px-4 mx-auto;
  }
  
  .text-shadow-sm {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
  
  .text-shadow-md {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .text-shadow-lg {
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
  }
  
  .text-shadow-title {
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.8), 
                -2px -2px 0 rgba(0, 0, 0, 0.8), 
                2px -2px 0 rgba(0, 0, 0, 0.8), 
                -2px 2px 0 rgba(0, 0, 0, 0.8);
  }
}
